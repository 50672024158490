import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("dataRegion", ["dataRegions", "selectedDataRegion"])
  },
  methods: {
    displayDataRegion(id) {
      let idToUse = id;
      // editing is used in edit profile
      if (this.editing && this.selectedDataRegion) {
        idToUse = this.selectedDataRegion.id;
      }
      if (
        this.dataRegions &&
        this.dataRegions.data &&
        this.dataRegions.data.length
      ) {
        const filtered = this.dataRegions.data.filter(
          item => idToUse === item.id
        );
        if (filtered && filtered.length) {
          return filtered[0].name;
        } else {
          return null;
        }
      }
      return null;
    }
  }
};
